import { Component } from '@angular/core';
import { options } from '@options';

@Component({
    selector: 'app-footer-adresses',
    templateUrl: './footer-adresses.component.html',
    styleUrls: ['./footer-adresses.component.scss'],
})
export class FooterAdressesComponent {
    readonly addresses = options.footerAddresses;
}
