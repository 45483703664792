import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-floating-label',
    templateUrl: './floating-label.component.html',
    styleUrls: ['./floating-label.component.scss'],
})
export class FloatingLabelComponent {
    @Input() iconName?: string;
    @Input() defaultStateClass?: string;
    @Input() value?: unknown;
    @Input() displayValue = true;
    @Input()
    get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(value: string | undefined) {
        this._placeholder = value ?? '';
    }

    private _placeholder: string = '';
}
