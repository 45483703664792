interface ShippingAddressDto {
    customer_no: string;
    code: string;
    restaurant_name: string;
    name: string;
    name2: string;
    city: string;
    county: string;
    country_region_code: string;
    post_code: string;
    address: string;
    address2: string;
    location_code: string;
    e_mail: string;
    street_code: string;
    contact: string;
    shipment_method_code: string;
}

export { ShippingAddressDto };
