export class LocalStorage {
    static write(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static remove(key: string): void {
        localStorage.removeItem(key);
    }

    static read<T = string>(item: string): T | null {
        try {
            return JSON.parse(<string>localStorage.getItem(item));
        } catch (error) {
            return null;
        }
    }
}
