<div class="h-10 text-black w-full text-xs z-30 relative | md:h-12 md:text-sm">
    <app-floating-label [placeholder]="placeholder" [value]="value" [displayValue]="false" [iconName]="iconName">
        <div class="flex items-center w-full pr-5 pl-12" [ngClass]="iconName ? 'pl-12' : 'pl-5'">
            <span class="transition-opacity duration-300" [class]="value ? 'opacity-100 ' : 'opacity-0'">{{ value }}</span>

            <app-icon
                name="ic:outline-keyboard-arrow-down"
                class="text-lg transition-transform !leading-4 ml-auto"
                [ngClass]="{ 'rotate-180': open }"
            ></app-icon>
        </div>
    </app-floating-label>
</div>
