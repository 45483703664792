import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, finalize, map, mergeMap, takeUntil, repeat, timer, merge, switchMap } from 'rxjs';
import { userActions } from '../actions/user.actions';
import { HttpService } from '../services/http.service';
import { LoadersFacade } from '@app/store/loader';
import { environment } from '@env';

@Injectable()
export class UserEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);

    private stopConditions$ = merge(this.actions$.pipe(ofType(userActions.clear, userActions.getUserError)));

    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.getUser),
            switchMap(() =>
                timer(0, environment.userReloadIntervalSeconds * 1000).pipe(
                    mergeMap((i) => {
                        if (i === 0) {
                            this.loadersFacade.add('get-users');
                        }
                        return this.httpService.getUser().pipe(
                            map((user) => userActions.getUserSuccess({ user })),
                            catchError(() => of(userActions.getUserError())),
                            finalize(() => {
                                if (i === 0) {
                                    this.loadersFacade.delete('get-users');
                                }
                            }),
                        );
                    }),
                ),
            ),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });

    changePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.changePassword),
            mergeMap(({ value }) => {
                this.loadersFacade.add('change-password');
                return this.httpService.changePassword(value).pipe(
                    map(() => userActions.changePasswordSuccess()),
                    catchError(() => of(userActions.changePasswordError())),
                    finalize(() => this.loadersFacade.delete('change-password')),
                );
            }),
        );
    });
}
