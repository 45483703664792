import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '../../icons';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorModule } from '../form-error/form-error.module';
import { FloatingLabelModule } from '../floating-label/floating-label.module';
import { SpinnerModule } from '../../components/spinner';

import { FormDropdownComponent } from './components/form-dropdown/form-dropdown.component';
import { PreviewComponent } from './components/preview/preview.component';
import { ListComponent } from './components/list/list.component';

@NgModule({
    imports: [CommonModule, IconsModule, ReactiveFormsModule, FormsModule, TranslateModule, FormErrorModule, FloatingLabelModule, SpinnerModule],
    declarations: [FormDropdownComponent, PreviewComponent, ListComponent],
    exports: [ReactiveFormsModule, FormDropdownComponent],
})
export class FormDropdownModule {}
