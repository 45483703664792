import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, inject } from '@angular/core';
import { BehaviorSubject, Subscription, delay, distinctUntilChanged, filter, take, tap } from 'rxjs';
import { DropdownService } from '../../service/dropdown.service';
import { Option } from '../../models/option.model';
import { isStringArray } from '../../utils/is-string-array';

@Component({
    selector: 'app-preview',
    styleUrls: ['./preview.component.scss'],
    templateUrl: './preview.component.html',
})
export class PreviewComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('input') input!: ElementRef<HTMLInputElement>;

    @Input({ required: true }) value: string | (string | null)[] | null = null;
    @Input({ required: true }) options: Option[] = [];
    @Input() placeholder?: string;
    @Input() iconName?: string;
    @Input() search!: boolean;

    private readonly dropdownService = inject(DropdownService);
    private readonly emptyValue = '';
    private readonly subscriptions$ = new Subscription();
    readonly open$ = this.dropdownService.open$;
    inputValue$ = new BehaviorSubject('');

    get name() {
        if (isStringArray(this.value)) {
            return this.options
                .filter((option) => (this.value as (string | null)[]).includes(option.value) ?? this.emptyValue === option.value)
                .map((option) => option.name)
                .join(', ');
        }

        return this.options.find((option) => (this.value ?? this.emptyValue) === option.value)?.name;
    }

    ngOnInit() {
        this.subscriptions$.add(
            this.open$
                .pipe(
                    distinctUntilChanged(),
                    delay(5),
                    tap((open) => {
                        this.onListVisibilityChange(open);
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.dropdownService.options$
                .pipe(
                    delay(5),
                    filter((options) => options.length > 0),
                    tap(() => {
                        if (this.name) {
                            this.inputValue$.next(this.name);
                        }
                    }),
                    take(1),
                )
                .subscribe(),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['value'] !== undefined) {
            this.inputValue$.next(this.name ?? '');
        }
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    onInput(event: Event) {
        const value = (event.target as HTMLInputElement).value;

        this.dropdownService.setSearchValue(value);
        this.inputValue$.next(value);
    }

    onListVisibilityChange(open: boolean) {
        if (!open) {
            this.dropdownService.setSearchValue('');
            this.inputValue$.next(this.name ?? '');

            return;
        }

        if (this.search) {
            this.input?.nativeElement?.focus();
        }
    }
}
