import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
    /** Find icon name at: https://icones.js.org/collection/all */

    @Input() name = '';
    @Input() style = '';
}
