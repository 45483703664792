<div class="box" [class.box-padding]="padding" [class.box-title]="title">
    <div *ngIf="title" class="flex items-center flex-wrap title">
        <div class="me-auto py-4">{{ title }}</div>
        <ng-content select="[titleRef]"></ng-content>
    </div>

    <div class="header mb-8">
        <ng-content select="[headerRef]"></ng-content>
    </div>

    <div class="content">
        <ng-content></ng-content>
    </div>
</div>
