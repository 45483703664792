interface ProductDto {
    item_id: string;
    item_no: string;
    item_category_subcode: string;
    item_description: string;
    item_description2: string;
    item_category_code: string;
    item_manufacturer_code: string;
    item_vat_rate: number;
    item_discount: number;
    item_product_type: string;
    item_expected_delivery_date: string;
    item_requested_receipt_date: string;
    item_qty_on_purch_order: number;
    inventory_on_stock: number;
    image_url: string;
    item_new_in: boolean;
    item_promo: boolean;
    item_availability: number;
    favorite: boolean;
    item_price_kg: number;

    // new Multi Units system
    item_base_uo_m: string;
    item_price_uom: number;
}

export { ProductDto };
