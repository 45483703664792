import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-filter-wrapper-label',
    templateUrl: './filter-wrapper-label.component.html',
    styleUrls: ['./filter-wrapper-label.component.scss'],
})
export class FilterWrapperLabelComponent {
    @Input({ required: true }) open = false;
    @Input({ required: true }) placeholder!: string;
    @Input({ required: true }) value!: unknown;
    @Input() iconName?: string;
}
