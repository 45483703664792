import { PaginationParams, TableParams } from '@app/shared/table';

interface BaseParamsInterface {
    page: string;
    limit: string;
    sort?: string;
    search?: string;
}

class BaseParams {
    protected baseParams: BaseParamsInterface;

    constructor(dto: TableParams & PaginationParams & { search?: string }) {
        this.baseParams = {
            page: dto.page.toString(),
            limit: dto.limit.toString(),
        };

        if (dto.sort && dto.direction) {
            this.baseParams.sort = `${dto.sort}:${dto.direction}`;
        }

        if (dto.search) {
            this.baseParams.search = dto.search;
        }
    }
}

export { BaseParams, BaseParamsInterface };
