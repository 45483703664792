import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChangePasswordValue, User } from '../models';

export const userActions = createActionGroup({
    source: 'User',
    events: {
        'Get User': emptyProps(),
        'Get User Success': props<{ user: User }>(),
        'Get User Error': emptyProps(),

        'Change Password': props<{ value: ChangePasswordValue }>(),
        'Change Password Success': emptyProps(),
        'Change Password Error': emptyProps(),

        Clear: emptyProps(),
    },
});
