import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';

@NgModule({
    imports: [CommonModule],
    declarations: [LanguageSwitchComponent],
    exports: [LanguageSwitchComponent],
})
export class I18nModule {}
