import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { IconsModule } from '@library';
import { I18nModule } from '@app/i18n';
import { ShippingAddressesModule } from '@app/modules/shipping-addresses';
import { ShellSharedModule } from '../../shared/shell-shared.module';
import { RouterModule } from '@angular/router';
import { ConcessionsModule } from '@app/modules/concessions';
import { StatusesContainerModule } from '@app/shared/components';
import { MobileMenuModule } from '@app/shared/components';
import { BlockadesModule } from '@app/modules/blockades';

import { ShellUserComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';

@NgModule({
    imports: [
        CommonModule,
        IconsModule,
        SharedModule,
        ShippingAddressesModule,
        I18nModule,
        ShellSharedModule,
        RouterModule,
        ConcessionsModule,
        BlockadesModule,
        StatusesContainerModule,
        MobileMenuModule,
    ],
    declarations: [ShellUserComponent, HeaderComponent],
})
export class ShellUserModule {}
