import { Injectable, inject } from '@angular/core';
import { of, catchError, concatMap, finalize, map, repeat, switchMap, takeUntil, filter, debounceTime, forkJoin, take, merge, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { LoadersFacade } from '@app/store/loader';
import { shippingAddressesActions } from '@app/store/shipping-addresses';
import { HttpService } from '../services/http.service';
import { shoppingCartActions } from '../actions/shopping-cart.actions';
import { selectCartDraft, selectCartItemsWithoutQtyPerUnit, selectCartPending } from '../selectors/shopping-cart.selector';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';
import { selectBlocked } from '@app/store/user/selectors/user.selector';

@Injectable()
export class ShoppingCartEffects {
    private messageService = inject(MessageService);
    private translateService = inject(TranslateService);
    private loadersFacade = inject(LoadersFacade);
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);
    private store = inject(Store);

    private stopConditions$ = merge(this.actions$.pipe(ofType(shippingAddressesActions.setAddress, shoppingCartActions.clear)));

    clear$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shippingAddressesActions.setAddress),
            map(() => shoppingCartActions.clear()),
        );
    });

    reload$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shippingAddressesActions.setAddressSuccess),
            map(() => shoppingCartActions.getCart()),
        );
    });

    getCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shoppingCartActions.getCart),
            switchMap(() => {
                this.loadersFacade.add('get-shopping-cart');
                return this.httpService.getCart().pipe(
                    map((cart) => shoppingCartActions.getCartSuccess({ cart })),
                    catchError(() => of(shoppingCartActions.getCartError())),
                    finalize(() => this.loadersFacade.delete('get-shopping-cart')),
                );
            }),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });

    updateCartDraft$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shoppingCartActions.updateCartDraft, shoppingCartActions.updateCartSuccess),
            debounceTime(200),
            concatLatestFrom(() =>
                forkJoin([this.store.select(selectCartDraft).pipe(take(1)), this.store.select(selectCartPending).pipe(take(1))]).pipe(
                    filter(([cartDraft, cartPending]) => cartDraft.length > 0 && cartPending.length === 0),
                    map(([cartDraft]) => cartDraft),
                ),
            ),
            map(([, cartDraft]) => cartDraft),
            map((value) => shoppingCartActions.updateCart({ value })),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });

    updateCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shoppingCartActions.updateCart),
            concatMap(({ value }) => {
                return this.httpService.updateCart(value).pipe(
                    map((cart) => shoppingCartActions.updateCartSuccess({ cart, value })),
                    catchError(() => of(shoppingCartActions.updateCartError())),
                );
            }),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });

    updateCartError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(shoppingCartActions.updateCartError),
                concatLatestFrom(() =>
                    this.store.select(selectBlocked).pipe(
                        filter((blockade) => blockade === 'Ship'),
                        take(1),
                    ),
                ),
                tap(([, isUserBlocked]) => {
                    if (isUserBlocked) {
                        this.messageService.add(this.translateService.instant('user-blocked'), 'warning');
                    }
                }),
            );
        },
        { dispatch: false },
    );

    // messages
    updateCartSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(shoppingCartActions.updateCartSuccess),
                tap(({ value }) => {
                    const addedQty = value
                        .map((item) => item.qty)
                        .filter((qty) => qty > 0)
                        .reduce((prev, next) => prev + next, 0);
                    const removedQty = value
                        .map((item) => item.qty)
                        .filter((qty) => qty < 0)
                        .reduce((prev, next) => prev - next, 0);

                    switch (addedQty % 10) {
                        case 0:
                            break;
                        case 1:
                            this.messageService.add(this.translateService.instant('cart.products.added.1', { qty: addedQty.toString() }), 'success');
                            break;
                        case 2:
                        case 3:
                        case 4:
                            this.messageService.add(this.translateService.instant('cart.products.added.2-4', { qty: addedQty.toString() }), 'success');
                            break;
                        default:
                            this.messageService.add(this.translateService.instant('cart.products.added.5+', { qty: addedQty.toString() }), 'success');
                            break;
                    }

                    switch (removedQty % 10) {
                        case 0:
                            break;
                        case 1:
                            this.messageService.add(this.translateService.instant('cart.products.removed.1', { qty: removedQty.toString() }), 'success');
                            break;
                        case 2:
                        case 3:
                        case 4:
                            this.messageService.add(this.translateService.instant('cart.products.removed.2-4', { qty: removedQty.toString() }), 'success');
                            break;
                        default:
                            this.messageService.add(this.translateService.instant('cart.products.removed.5+', { qty: removedQty.toString() }), 'success');
                            break;
                    }
                }),
                takeUntil(this.stopConditions$),
                repeat(),
            );
        },
        { dispatch: false },
    );

    updateCartSuccessQtyPerUnit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shoppingCartActions.updateCartSuccess, shoppingCartActions.getCartSuccess),
            concatLatestFrom(() => this.store.select(selectCartItemsWithoutQtyPerUnit).pipe(take(1))),
            map(([, cart]) => cart.map((item) => ({ id: item.id, no: item.no, unit: item.unit }))),
            filter((items) => items.length > 0),
            concatMap((items) => {
                return this.httpService.getProductsQtyPerUnit(items).pipe(
                    map((items) => shoppingCartActions.getProductQtyPerUnitSuccess({ items })),
                    catchError(() => of(shoppingCartActions.getProductQtyPerUnitError({ items }))),
                );
            }),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });
}
