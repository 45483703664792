import { ProductDto } from './product.dto';

export class Product {
    id: string;
    no: string;
    categorySubcode: string;
    name: string;
    categoryCode: string;
    manufacturerCode: string;
    // price: number; // # DEPRECATED
    // priceAfterDiscount: number; // # DEPRECATED
    vatRate: number;
    discount: number;
    productType: string;
    expectedDeliveryDate: string;
    confirmedDeliveryDate: string;
    qtyOnPurchOrder: number;
    quantity: number;
    // unit: string; // # DEPRECATED
    imageId: string;
    isNew: boolean;
    isPromo: boolean;
    availability: number;
    pricePerKg: number;
    discountPricePerKg: number;

    // new Multi Units system
    defaultUnit: string;
    defaultPrice: number;
    defaultDiscountPrice: number;
    favorite: boolean;

    constructor(productDto: ProductDto) {
        this.id = productDto.item_id;
        this.no = productDto.item_no;
        this.categorySubcode = productDto.item_category_subcode;
        this.name = `${productDto.item_description}${productDto.item_description2}`;
        this.categoryCode = productDto.item_category_code;
        this.manufacturerCode = productDto.item_manufacturer_code;
        // this.price = Math.round(100 * productDto.item_price); // # DEPRECATED
        // this.priceAfterDiscount = Math.round(100 * productDto.item_price * (1 - productDto.item_discount / 100)); // # DEPRECATED
        this.vatRate = productDto.item_vat_rate;
        this.discount = productDto.item_discount;
        this.productType = productDto.item_product_type;
        this.expectedDeliveryDate = productDto.item_expected_delivery_date;
        this.confirmedDeliveryDate = productDto.item_requested_receipt_date;
        this.qtyOnPurchOrder = productDto.item_qty_on_purch_order;
        this.quantity = productDto.inventory_on_stock && productDto.inventory_on_stock < 0 ? 0 : productDto.inventory_on_stock;
        this.availability = productDto.item_availability && productDto.item_availability < 0 ? 0 : productDto.item_availability;
        this.favorite = productDto.favorite;
        // this.unit = productDto.item_base_uo_m;  // # DEPRECATED
        this.imageId = productDto.image_url;
        this.isNew = productDto.item_new_in;
        this.isPromo = productDto.item_promo;
        this.pricePerKg = Math.round(100 * productDto.item_price_kg);
        this.discountPricePerKg = Math.round(100 * productDto.item_price_kg * (1 - productDto.item_discount / 100));

        // new Multi Units system
        this.defaultUnit = productDto.item_base_uo_m;
        this.defaultPrice = Math.round(100 * productDto.item_price_uom);
        this.defaultDiscountPrice = Math.round(100 * productDto.item_price_uom * (1 - productDto.item_discount / 100));
    }
}
