import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-box',
    templateUrl: './box.component.html',
    styleUrls: ['./box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent {
    @Input() title: string | null = null;
    @Input() padding: boolean = true;
}
