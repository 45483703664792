import { createReducer, on } from '@ngrx/store';
import { userActions } from '../actions/user.actions';
import { User } from '../models';

export interface UserState {
    loaded: boolean | null;
    user: User | null;
}

export const initialState: UserState = {
    loaded: null,
    user: null,
};

export const userReducer = createReducer(
    initialState,
    on(
        userActions.getUser,
        (state): UserState => ({
            ...state,
            loaded: null,
        }),
    ),
    on(
        userActions.getUserSuccess,
        (state, payload): UserState => ({
            ...state,
            loaded: true,
            user: payload.user,
        }),
    ),
    on(
        userActions.getUserError,
        (state): UserState => ({
            ...state,
            loaded: false,
        }),
    ),
    on(
        userActions.clear,
        (): UserState => ({
            ...initialState,
        }),
    ),
);
