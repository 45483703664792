import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterWrapperComponent } from './filter-wrapper.component';
import { IconsModule } from '@library';
import { FloatingLabelModule } from '@library';
import { FilterWrapperLabelComponent } from './components/filter-wrapper-label/filter-wrapper-label.component';

@NgModule({
    declarations: [FilterWrapperComponent, FilterWrapperLabelComponent],
    imports: [CommonModule, IconsModule, FloatingLabelModule],
    exports: [FilterWrapperComponent],
})
export class FilterWrapperModule {}
