import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailabilityDotsComponent } from './availability-dots.component';

@NgModule({
    declarations: [AvailabilityDotsComponent],
    imports: [CommonModule],
    exports: [AvailabilityDotsComponent],
})
export class AvailabilityDotsModule {}
