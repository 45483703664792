<div class="p-2 text-sm h-full grow overflow-y-auto" #list>
    <div class="p-3 grid place-content-center w-full" *ngIf="loading; else listRef">
        <app-spinner />
    </div>

    <ng-template #listRef>
        <ng-container *ngIf="options.length; else noData">
            <button *ngFor="let option of options" (click)="selectOption($event, option.value)" [class.selected]="selected(option.value)">
                {{ option.name }}
            </button>

            <app-spinner *ngIf="pageLoading" />
        </ng-container>

        <ng-template #noData>
            <div class="p-3" translate>table.no-content</div>
        </ng-template>
    </ng-template>
</div>
