import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-availability-dots',
    templateUrl: './availability-dots.component.html',
    styleUrls: ['./availability-dots.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityDotsComponent {
    @Input({ required: true }) quantity = 0;

    private readonly scores = ['#fb964d', '#fdd244', '#b0dc53', '#35cc62'];

    getAvailabilityColor = (index: number) => {
        const score = Math.floor(this.quantity / 250);
        let backgroundColor = '#F2F2F2';

        if (score > 3) {
            backgroundColor = this.scores[3];
        } else if (score >= index && this.quantity !== 0) {
            backgroundColor = this.scores[score];
        }

        return backgroundColor;
    };
}
