import { Alerts } from '@corelabs/angular-account/models/alerts';

export const constants = {
    table: {
        limit: 12,
    },
    icons: 'assets/svgs/icons.svg',
    userSwitch: {
        keys: {
            localStorage: 'X-Switch-Customer',
            header: 'X-Switch-Customer',
        },
    },
    addressSwitch: {
        keys: {
            localStorage: 'X-Switch-Shipping-Address',
            header: 'X-Switch-Shipping-Address',
        },
    },
    productDiscounts: [
        { id: 'any', value: '' },
        { id: 'promotions', value: 'promotions' },
        { id: 'novelties', value: 'novelties' },
    ],
    productCheckboxCategories: [
        { id: 'recently', value: '1000' },
        { id: 'less-frequently', value: '1100' },
    ],
    alerts: {
        skip: ['login-invalid-credentials'],
    } as Alerts,
    disabledDates: [new Date('2023-12-23')],
};
