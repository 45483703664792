import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { Option } from '../models/option.model';

@Injectable()
export class DropdownService {
    searchValue$ = new BehaviorSubject('');

    options$ = new BehaviorSubject<Option[]>([]);
    open$ = new BehaviorSubject(false);
    filteredOptions$ = combineLatest([this.options$, this.searchValue$]).pipe(
        map(([options, searchValue]) =>
            options.filter((option) => {
                return option.name.toLowerCase().includes(searchValue.toLowerCase());
            }),
        ),
    );

    setOpen(value: boolean) {
        this.open$.next(value);
    }

    setSearchValue(value: string) {
        this.searchValue$.next(value);
    }

    setOptions(value: Option[]) {
        this.options$.next(value);
    }
}
