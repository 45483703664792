import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@library';

import { MobileMenuButtonComponent } from './components/mobile-menu-button/mobile-menu-button.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';

@NgModule({
    imports: [CommonModule, IconsModule, TranslateModule, RouterModule],
    declarations: [MobileMenuButtonComponent, MobileMenuComponent],
    exports: [MobileMenuButtonComponent],
})
export class MobileMenuModule {}
