<div class="relative text-xs pl-5 pr-12 h-10 | md:h-12 md:text-sm">
    <input
        #input
        *ngIf="search"
        type="text"
        class="peer absolute top-0 left-0 z-[2] pr-12 w-full h-full outline-none bg-transparent rounded-3xl"
        [ngClass]="[(open$ | async) ? '' : 'cursor-pointer', iconName ? 'pl-12' : 'pl-5']"
        [tabindex]="search ? 0 : -1"
        [value]="inputValue$ | async"
        (input)="onInput($event)"
    />

    <div class="absolute right-5 top-1/2 -translate-y-1/2 z-[1]">
        <app-icon name="ic:outline-keyboard-arrow-down" class="text-lg text-gray-400 !leading-4" [class.rotate-180]="open$ | async"></app-icon>
    </div>

    <app-floating-label [placeholder]="placeholder ?? ''" [value]="inputValue$ | async" [displayValue]="!search" [iconName]="iconName"></app-floating-label>
</div>
