<div class="h-full flex items-center">
    <span
        *ngIf="placeholder"
        class="floating-label | absolute transition-all duration-300 pointer-events-none text-gray-500 text-xs md:text-sm"
        [ngClass]="{ 'floating-state': value, '!left-12': iconName && !value }"
        [class]="value ? '' : defaultStateClass ?? ''"
    >
        {{ placeholder }}
    </span>

    <app-icon *ngIf="iconName" [name]="iconName" class="absolute pl-5 left-0 top-1/2 -translate-y-1/2 text-lg !leading-4"></app-icon>

    <span *ngIf="value && displayValue" [title]="value" class="truncate w-full inline-block">
        {{ value }}
    </span>

    <ng-content></ng-content>
</div>
