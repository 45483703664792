import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ProductDiscountsFacade } from '../facades/product-discounts.facade';

export function productDiscountsCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const productDiscountsFacade = inject(ProductDiscountsFacade);

        productDiscountsFacade.clear();
        return true;
    };
}
