import { Pipe, PipeTransform, inject } from '@angular/core';
import { ShoppingCartFacade } from '@app/store/shopping-cart';
import { Observable } from 'rxjs';

@Pipe({
    name: 'cartProcessing',
})
export class CartProcessingPipe implements PipeTransform {
    private cartFacade = inject(ShoppingCartFacade);

    transform(): Observable<boolean> {
        return this.cartFacade.cartProcessing$;
    }
}
