import { UserBlocked } from '@app/store/models';
import { Role, UserDto, UserSalespersonDto, UserSalespersonInfoDto } from './user.dto';
import { options } from '@options';
import { Feature } from '@app/shared/types';

export class User {
    id: string;
    name: string;
    roles: Role[];
    features: Feature[];
    salesperson: UserSalesperson | null;
    salespersonInfo: UserSalespersonInfo | null;

    constructor(userDto: UserDto) {
        this.id = userDto.id;
        this.name = userDto.name;
        this.roles = userDto.roles;
        this.salesperson = userDto.salesperson ? new UserSalesperson(userDto.salesperson) : null;
        this.salespersonInfo = userDto.salesperson_info ? new UserSalespersonInfo(userDto.salesperson_info) : null;

        const role = this.roles[0];
        this.features = role ? options.roles[role] : [];
    }
}

export class UserSalesperson {
    code: string;
    email: string;
    name: string;
    role: string;
    locationCode: string;
    locationFilters: string[];
    image: string;

    constructor(data: UserSalespersonDto) {
        this.code = data.code;
        this.email = data.email;
        this.name = data.name;
        this.role = data.role;
        this.locationCode = data.location_code;
        this.locationFilters = data.location_filter;
        this.image = data.image;
    }
}

class UserSalespersonInfo {
    no: string;
    name: string;
    balance: number;
    shipmentBalance: number;
    balanceDue: number;
    creditLimitLcy: number;
    salesPersonCode: string;
    locationCode: string;
    blocked: UserBlocked | null;
    restaurantName: string;
    vatRegistrationNo: string;
    postcode: string;
    city: string;
    addresses: string;
    paymentTerms: string;
    paymentMethod: string;
    skipCreditLimitVerification: boolean;
    economicBlockCondition: boolean;

    constructor(data: UserSalespersonInfoDto) {
        this.no = data.no;
        this.name = `${data.name}${data.name2 ?? ''}`.trim();
        this.balance = Math.round(100 * Number(data.balance ?? 0));
        this.shipmentBalance = Math.round(100 * Number(data.shipped_not_invoiced_lcy ?? 0));
        this.balanceDue = Math.round(100 * Number(data.balance_due ?? 0));
        this.creditLimitLcy = Math.round(100 * Number(data.credit_limit_lcy ?? 0));
        this.salesPersonCode = data.sales_person_code;
        this.locationCode = data.location_code;
        this.blocked = data.blocked === '' || data.blocked === ' ' ? null : data.blocked === 'Vindication' ? 'Ship' : data.blocked;
        this.restaurantName = data.restaurant_name;
        this.vatRegistrationNo = data.vat_registration_no;
        this.postcode = data.post_code;
        this.city = data.city;
        this.addresses = data.addresses;
        this.paymentTerms = data.payment_terms;
        this.paymentMethod = data.payment_method;
        this.skipCreditLimitVerification = data.skip_credit_limit_verification;
        this.economicBlockCondition = Boolean(data.economic_block_condition);
    }
}
