import { Options } from '../options';

export const options: Options = {
    transportMethods: [
        { id: 'transport', value: 'TRANSPORT' },
        { id: 'pickup', value: 'ODBIÓR OSO' },
    ],
    logoSrc: {
        white: 'assets/svgs/mille/logo-white.svg',
        dark: 'assets/svgs/mille/logo-dark.svg',
    },
    cart: {
        productsProposal: {
            linkToProduct: true,
            productNoField: true,
            useRarelyPurchasedItems: true,
        },
    },
    products: {
        lowAvailability: {
            availabilityValue: 5,
            markValueAsDanger: true,
            cartItemTooltip: true,
            addToCartAlert: true,
            quantityInputAlert: true,
        },
    },
    documents: {
        typeFilters: true,
    },
    roles: {
        ROLE_ADMIN: [],
        ROLE_SALESPERSON: [
            'documents-financial',
            'documents-liabilities',
            'documents-sales',
            'customer.table.icon',
            'customer.table.no',
            'customer.table.vat-no',
            'customer.table.company-name',
            'customer.table.due-balance',
            'customer.table.restaurant-name',
            'customer.table.blocked',
            'customer.table.display-as',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
            'product-details.product-card',
            'nav.dashboard',
            'nav.products',
            'nav.documents',
            'nav.categories',
            'merchant-limit',
            'merchant-limit.condition.shipment-balance',
            'complaints.form',
            'complaints.list',
            'complaints.list.date',
            'complaints.list.status',
        ],
        ROLE_USER: [
            'documents-financial',
            'documents-liabilities',
            'documents-sales',
            'financial-documents.payments',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
            'nav.dashboard',
            'nav.products',
            'nav.documents',
            'nav.categories',
            'merchant-limit',
            'merchant-limit.condition.shipment-balance',
            'complaints.form',
            'complaints.list',
            'complaints.list.date',
            'complaints.list.status',
        ],
        ROLE_USER_LIMITED: [
            'documents-sales',
            'financial-documents.payments',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
            'nav.dashboard',
            'nav.products',
            'nav.documents',
            'nav.categories',
            'merchant-limit',
            'merchant-limit.condition.shipment-balance',
            'complaints.form',
            'complaints.list',
            'complaints.list.date',
            'complaints.list.status',
        ],
    },
    standardFooter: true,
    footerAddresses: [],
};
