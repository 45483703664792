<button [class.active]="open$ | async" [class.invisible]="open$ | async" (click)="onClick()">
    <span></span>
    <span></span>
    <span></span>
</button>

<app-mobile-menu @Menu *ngIf="open$ | async">
    <ng-content></ng-content>
    <div class="top-8 right-8 absolute text-[0]">
        <button class="active" (click)="onClick()">
            <span></span>
            <span></span>
            <span></span>
        </button>
    </div>
</app-mobile-menu>
