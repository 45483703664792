import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { BehaviorSubject, Subscription, filter, tap } from 'rxjs';
import { Grow } from './animations/grow.animation';
import { FilterWrapperService } from './services/filter-wrapper.service';

@Component({
    selector: 'app-filter-wrapper',
    templateUrl: './filter-wrapper.component.html',
    styleUrls: ['./filter-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Grow],
    providers: [FilterWrapperService],
})
export class FilterWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
    private filterWrapperService = inject(FilterWrapperService);
    @ViewChild('filterWrapper') filterWrapper!: ElementRef<HTMLDivElement>;

    @Input() placeholder = '';
    @Input({ required: true }) selectedValue = '';
    @Input() iconName?: string;

    open$ = new BehaviorSubject<boolean>(false);
    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.subscriptions$.add(this.filterWrapperService.close$.pipe(tap(() => this.open$.next(false))).subscribe());
    }

    ngAfterViewInit(): void {
        this.subscriptions$.add(
            this.open$
                .pipe(
                    filter((open) => open === false),
                    tap(() => this.filterWrapper.nativeElement.blur()),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    onFocusIn() {
        this.open$.next(true);
    }

    onFocusOut(event: FocusEvent) {
        const target = <HTMLElement>event.relatedTarget;
        if (this.filterWrapper.nativeElement.contains(target) === false) {
            this.open$.next(false);
        }
    }
}
