import { Component, Input, inject } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormErrorTypes } from '../enums/form-error-types.enum';
import { Grow } from '../../animations';

@Component({
    selector: 'app-form-error',
    styleUrls: ['./form-error.component.scss'],
    templateUrl: './form-error.component.html',
    animations: [Grow],
})
export class FormErrorComponent {
    private readonly translateService = inject(TranslateService);
    @Input() errors!: ValidationErrors | null;

    params = {};

    get messages(): string[] {
        if (this.errors) {
            return Object.keys(this.errors).reduce(
                (errors, type) => {
                    switch (type) {
                        case FormErrorTypes.WORDS:
                            errors.push(<string>this.translateService.instant('forms.error.message.words'));
                            break;
                        case FormErrorTypes.WORDS_AND_NUMBERS:
                            errors.push(<string>this.translateService.instant('forms.error.message.words-and-numbers'));
                            break;
                        case FormErrorTypes.NUMBERS:
                            errors.push(<string>this.translateService.instant('forms.error.message.numbers'));
                            break;
                        case FormErrorTypes.REQUIRED:
                            errors.push(<string>this.translateService.instant('forms.error.message.required'));
                            break;
                        case FormErrorTypes.CUSTOMS:
                            if (this.errors) {
                                errors.push(...(<string[]>this.errors[type]));
                            }
                            break;
                        case FormErrorTypes.GRACEFUL:
                            // do nothing
                            break;
                        case FormErrorTypes.INVALID_ADDRESS:
                            errors.push(<string>this.translateService.instant('forms.error.message.invalid_address'));
                            break;
                        case FormErrorTypes.INVALID_STREET:
                            errors.push(<string>this.translateService.instant('forms.error.message.invalid_street'));
                            break;
                        case FormErrorTypes.INVALID_STREET_NUMBER:
                            errors.push(<string>this.translateService.instant('forms.error.message.invalid_street_number'));
                            break;
                        case FormErrorTypes.MUST_MATCH:
                            errors.push(<string>this.translateService.instant('forms.error.message.new_password'));
                            break;
                        case FormErrorTypes.REGISTER_INVALID_EMAIL:
                            errors.push(<string>this.translateService.instant('forms.error.message.register_email_not_match'));
                            break;
                        case FormErrorTypes.INVALID_RETURN_DATE:
                            errors.push(<string>this.translateService.instant('forms.error.message.invalid_return_date'));
                            break;
                        case FormErrorTypes.MIN_LENGTH:
                            if (this.errors) {
                                const params = <{ requiredLength: number }>this.errors[type];
                                errors.push(<string>this.translateService.instant('forms.error.message.min_length', { min_length: params.requiredLength }));
                            }
                            break;
                        case FormErrorTypes.MAX_LENGTH:
                            if (this.errors) {
                                const params = <{ requiredLength: number }>this.errors[type];
                                errors.push(<string>this.translateService.instant('forms.error.message.max_length', { max_length: params.requiredLength }));
                            }
                            break;
                        case FormErrorTypes.INVALID_LOOKUP_ADDRESS:
                            errors.push(<string>this.translateService.instant('forms.error.message.invalid_lookup_id'));
                            break;
                        default:
                            errors.push(<string>this.translateService.instant('forms.error.message.general'));
                    }
                    return errors;
                },
                <string[]>[],
            );
        }
        return [];
    }

    get firstMessage() {
        return this.messages[0];
    }
}
