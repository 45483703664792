import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { BehaviorSubject, Subscription, filter, tap } from 'rxjs';
import { Menu } from '../../animations/slide.animation';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-mobile-menu-button',
    templateUrl: './mobile-menu-button.component.html',
    animations: [Menu],
    styleUrls: ['./mobile-menu-button.component.scss'],
})
export class MobileMenuButtonComponent implements OnInit, OnDestroy {
    private router = inject(Router);

    open$ = new BehaviorSubject<boolean>(false);
    private subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.subscriptions$.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationStart),
                    tap(() => this.open$.next(false)),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.open$.next(false);
        this.subscriptions$.unsubscribe();
    }

    onClick() {
        this.open$.next(!this.open$.value);
    }
}
