import { Component, inject } from '@angular/core';
import { map } from 'rxjs';
import { footer } from '@footer';
import { options } from '@options';
import { UserFacade } from '@app/store/user';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    private readonly userFacade = inject(UserFacade);
    readonly footerAddresses = options.footerAddresses;
    readonly standardFooterVisibility = options.standardFooter;
    readonly shopLinks = footer.shopLinks;
    readonly contact = footer.contact;
    readonly features$ = this.userFacade.features$;
    readonly footerLinks$ = this.features$.pipe(map((features) => footer.footerLinks.filter((e) => (e.feature ? features.includes(e.feature) : true))));
}
