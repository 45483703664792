import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { Lang } from '@lang';

import * as plPL from '../../../translations/pl-PL.json';
import * as plPLOverrwrite from '../../../translations/_templates/pl-PL.json';

import * as enEN from '../../../translations/en-EN.json';
import * as enENOverrwrite from '../../../translations/_templates/en-EN.json';

import * as esES from '../../../translations/es-ES.json';
import * as esESOverrwrite from '../../../translations/_templates/es-ES.json';

const languages: Record<Lang, unknown> = {
    'pl-PL': { ...plPL, ...plPLOverrwrite },
    'en-EN': { ...enEN, ...enENOverrwrite },
    'es-ES': { ...esES, ...esESOverrwrite },
};

export class TranslateLoaderStatic implements TranslateLoader {
    getTranslation(lang: Lang) {
        return new Observable((observer) => {
            observer.next(languages[lang]);

            observer.complete();
        });
    }
}
