import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { Subscription, debounceTime, fromEvent, tap } from 'rxjs';
import { Option } from '../../models/option.model';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnDestroy, AfterViewInit {
    private readonly ngZone = inject(NgZone);

    @ViewChild('list') list!: ElementRef<HTMLDivElement>;

    @Input({ required: true }) options!: Option[];
    @Input({ required: true }) value!: string | string[] | null;
    @Output() selectEvent = new EventEmitter<string | null>();
    @Output() loadMoreOptions = new EventEmitter();
    @Input() lazyLoading!: boolean;
    @Input() pageLoading!: boolean;
    @Input() loading!: boolean;

    private readonly subscriptions$ = new Subscription();

    selected(option: string | null) {
        return Array.isArray(this.value) ? this.value?.some((opt) => opt === option) : this.value === option;
    }

    selectOption(event: Event, value: string | null) {
        event.preventDefault();
        this.selectEvent.next(value);
    }

    ngAfterViewInit() {
        if (this.lazyLoading) {
            this.handleLazyLoading();
        }
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    private handleLazyLoading() {
        this.ngZone.runOutsideAngular(() => {
            this.subscriptions$.add(
                fromEvent(this.list.nativeElement, 'scroll')
                    .pipe(
                        debounceTime(100),
                        tap(() => this.checkScrollPosition()),
                    )
                    .subscribe(),
            );
        });
    }

    private checkScrollPosition() {
        this.ngZone.run(() => {
            const scrollHeight = this.list.nativeElement.scrollHeight;
            const height = this.list.nativeElement.clientHeight;
            const scrollTop = this.list.nativeElement.scrollTop;

            if (scrollTop > scrollHeight - height - 50 && !this.pageLoading && !this.loading) {
                this.loadMoreOptions.emit();
            }
        });
    }
}
