import { Injectable } from '@angular/core';
import { fromEvent, map, merge, shareReplay, startWith } from 'rxjs';
import { Size } from '../enums/size.enum';

@Injectable({ providedIn: 'root' })
export class WindowService {
    matchMedia$ = (size: number) => {
        const mediaQuery = window.matchMedia(`(min-width: ${size}px)`);

        return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
            startWith(mediaQuery),
            map((list: MediaQueryList) => list.matches),
        );
    };

    xl$ = this.matchMedia$(Size.XL).pipe(shareReplay());
    lg$ = this.matchMedia$(Size.LG).pipe(shareReplay());
    md$ = this.matchMedia$(Size.MD).pipe(shareReplay());
    sm$ = this.matchMedia$(Size.SM).pipe(shareReplay());
    xs$ = this.matchMedia$(Size.XS).pipe(shareReplay());

    scroll$ = fromEvent(window, 'scroll');
    resize$ = fromEvent(window, 'resize');

    change$ = merge(this.scroll$, this.resize$);

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
