import { ProductDto } from '../../models';

/**
 * ShoppingCartDto
 *
 * @param id stray
 * @param name stray
 * @param price_net stray
 * @param price_gross stray
 */
interface ShoppingCartItemDto {
    id: string;
    name: string;
    item_id: string;
    item_no: string;
    item_uom: string;
    price_net: number;
    price_gross: number;
    qty: number;
    item: ProductDto;
}

export { ShoppingCartItemDto };
