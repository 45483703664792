import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, inject } from '@angular/core';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements AfterViewInit, OnDestroy {
    private elementRef = inject(ElementRef);
    private renderer = inject(Renderer2);

    ngAfterViewInit(): void {
        this.renderer.appendChild(document.body, this.elementRef.nativeElement);
    }

    ngOnDestroy() {
        this.renderer.removeChild(document.body, this.elementRef.nativeElement);
    }
}
