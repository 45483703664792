<div
    #dropdown
    class="dropdown"
    [class.dropdown-open]="(open$ | async) || false"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut($event)"
    [tabindex]="search ? -1 : 0"
>
    <div [ngClass]="{ '!shadow-error !border-eip-danger/75': control.touched && control.invalid }">
        <app-preview [placeholder]="placeholder" [options]="(options$ | async) ?? []" [value]="control.getRawValue()" [iconName]="iconName" [search]="search" />

        <div class="list" @Grow *ngIf="(open$ | async) === true">
            <app-list
                [options]="lazyLoading ? (options$ | async) ?? [] : (filteredOptions$ | async) ?? []"
                [value]="control.getRawValue() ?? emptyValue"
                [lazyLoading]="lazyLoading"
                (selectEvent)="onSelectEvent($event)"
                (loadMoreOptions)="loadMoreOptions.emit()"
                [pageLoading]="pageLoading"
                [loading]="loading"
            />
        </div>
    </div>
</div>

<app-form-error *ngIf="showErrors && control.touched" [errors]="control.errors"></app-form-error>

<div *ngIf="helptext" class="text-xs md:text-sm pt-1 text-gray-500">{{ helptext }}</div>
