import { trigger, transition, animate, style } from '@angular/animations';

export const Menu = trigger('Menu', [
    transition(':enter', [
        style({
            overflow: 'hidden',
            opacity: 0,
        }),
        animate(
            '0.3s ease-in-out',
            style({
                opacity: 1,
            }),
        ),
    ]),
    transition(':leave', [
        style({
            overflow: 'hidden',
        }),
        animate(
            '0.3s ease-in-out',
            style({
                opacity: 0,
            }),
        ),
    ]),
]);
