import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { User, UserDto } from '../models';
import { ChangePasswordValue } from '../models/change-password.value';
import { ChangePasswordValueDto } from '../models/change-password.value.dto';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getUser(): Observable<User> {
        return this.httpClient
            .addUserSwitch()
            .get<ApiResponse<UserDto>>(`api/v1/users/me`)
            .pipe(map((response) => new User(response.data)));
    }

    changePassword(value: ChangePasswordValue) {
        const params = new ChangePasswordValueDto(value);

        return this.httpClient.put('api/v1/users/password', params);
    }
}
