import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClient, HttpBackend, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { I18nModule, TranslateLoaderStatic } from './i18n';
import { environment } from '@env';
import { ShellUserModule, ShellSalesModule, ShellAuthModule } from './shells';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CoreModule } from '@corelabs/angular-core';
import { AppComponent } from './app.component';
import { HttpCoreService } from './core/services/http.service';
import { MessagesModule } from './modules/messages';
// Stores
import { UserStoreModule } from './store/user';
import { LoadersStoreModule } from './store/loader';
import { ShippingAddressesStoreModule } from '@app/store/shipping-addresses';
import { ShoppingCartStoreModule } from './store/shopping-cart';
import { ProductDiscountsStoreModule } from '@app/store/product-discounts';
import { ProductSubstitutesStoreModule } from './store/product-substitutes';
import { ProductPricingStoreModule } from './store/product-pricing';
import { FavouritesStoreModule } from './store/favourites';
import { ConcessionsStoreModule } from './store/concessions';
import { AccountModule } from '@corelabs/angular-account';
import { constants } from '@const';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        I18nModule,
        UserStoreModule,
        LoadersStoreModule,
        MessagesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: () => {
                    return new TranslateLoaderStatic();
                },
                deps: [HttpBackend],
            },
        }),
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            // Devtools mmust be imported after StoreModule
            serialize: true,
            maxAge: 25,
            logOnly: environment.production,
        }),
        ShippingAddressesStoreModule,
        ShellAuthModule,
        ShellUserModule,
        ShellSalesModule,
        AppRoutingModule,
        ConcessionsStoreModule,
        ShoppingCartStoreModule,
        ProductDiscountsStoreModule,
        ProductSubstitutesStoreModule,
        ProductPricingStoreModule,
        FavouritesStoreModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        CoreModule.forRoot({ serverUrl: environment.serverUrl }),
        AccountModule.forRoot(constants.alerts),
    ],
    providers: [
        {
            provide: HttpClient,
            useClass: HttpCoreService,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
