<div class="grid-container-fill | grid gap-x-5 gap-y-14">
    <div *ngFor="let item of addresses" class="flex flex-col justify-between text-white/95">
        <h3 class="w-max uppercase inline-block border-b mb-3.5 px-1.5 -ml-1.5">{{ item.name }}</h3>

        <div class="mb-3.5 text-sm">
            <div *ngFor="let address of item.address">{{ address }}</div>
        </div>

        <div class="mb-3.5 text-sm">
            <div *ngFor="let info of item.additional">{{ info }}</div>
        </div>

        <a class="mt-auto border border-white py-1.5 px-2.5 w-max inline-flex items-center gap-1.5 hover:bg-white/10" [href]="item.googleMaps" target="_blank">
            <span class="text-sm">{{ 'footer.open-map' | translate }}</span>
            <app-icon class="text-md" name="material-symbols:location-on" />
        </a>
    </div>
</div>
