<div class="min-h-screen grid grid-rows-[auto,1fr,auto]">
    <div class="sticky top-0 z-50">
        <app-header></app-header>
    </div>

    <section class="py-12 mx-5 | 3xl:container 3xl:mx-auto overflow-x-hidden">
        <div class="p-5 md:p-10">
            <router-outlet></router-outlet>
        </div>
    </section>

    <app-footer></app-footer>
</div>
