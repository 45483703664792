import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import 'iconify-icon';
import { IconComponent } from './icon/icon.component';

@NgModule({
    imports: [CommonModule],
    declarations: [IconComponent],
    exports: [IconComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IconsModule {}
