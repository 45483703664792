import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorComponent } from './form-error.component';

@NgModule({
    imports: [CommonModule, TranslateModule],
    declarations: [FormErrorComponent],
    exports: [FormErrorComponent],
})
export class FormErrorModule {}
