import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared';
import { IconsModule } from '@library';
import { ShellSharedModule } from '../../shared/shell-shared.module';
import { I18nModule } from '@app/i18n';
import { RouterModule } from '@angular/router';
import { ShippingAddressesModule } from '@app/modules/shipping-addresses';

import { ShellSalesComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { UserActionsComponent } from './components/header/components/user-actions/user-actions.component';

@NgModule({
    imports: [CommonModule, IconsModule, SharedModule, I18nModule, ShellSharedModule, RouterModule, ShippingAddressesModule],
    declarations: [ShellSalesComponent, HeaderComponent, UserActionsComponent],
})
export class ShellSalesModule {}
